import moment from 'moment-mini';
import Ajax from './Ajax';

/**
* Utility helper for Account functions
*/

class Utility {
  constructor() {
    this.apiUrl = process.env.SERVER_URL;
    this.allUtility = null;
    this.previewUtility = null;
  }

  sortUtility = (creatorId, filterType, token) => {
    let filteredUtilities = [];
  
    // offers is 1 or 4 or 3
    if (filterType === 2) {
      filteredUtilities = this.allUtility.filter((e) => e.utilityType === 2);
    } else if (filterType === 3) {
      filteredUtilities = this.allUtility.filter((e) => e.utilityType === 4 || e.utilityType === 1 || e.utilityType === 3);
    } else if (filterType === 6) {
      filteredUtilities = this.allUtility.filter((e) => e.utilityType === 6);
    } else {
      filteredUtilities = this.allUtility;
    }
  
    // Filter out duplicates based on utilityId
    const seenUtilityIds = new Set();
    const uniqueFilteredUtilities = filteredUtilities.filter((e) => {
      if (seenUtilityIds.has(e.utilityId)) {
        return false;
      }
      seenUtilityIds.add(e.utilityId);
      return true;
    });
  
    return uniqueFilteredUtilities;
  }

  getUtilityThumbnail = (utility) => {
    if (utility.utilityType === 1) {
      // return utility.voucher.voucherImageUrl;
    } if (utility.utilityType === 2) {
      return utility.gatedContent.thumbnail;
    } if (utility.utilityType === 3) {
      return utility.gatedCommerce.productThumbnail;
    } if (utility.utilityType === 4) {
      return utility.genericPromotion.thumbnailUrl;
    } if (utility.utilityType === 6) {
      return utility.contests.thumbnailImage;
    }
    return 'https://assets.heyethos.com/diageo/utility/thumb-blue-recipe2.jpg';
  }

  categorizeAssetUtility = (allUtility) => {
    const sortedUtility = [];
    const currentDate = moment().unix() * 1000;
    let utilityObj;

    allUtility.forEach((utility) => {
      if (utility.utilityDescription.includes('Event:')) {
        console.log('Event found');
        console.log(utility);
        // newCategorizedUtility.push(utility);
        utility.utilityType = 5;
      }
    });

    // if (allUtility.filter((e) => e.utilityType === 3).length > 0) {
    //   console.log('=== merch ===');
    //   console.log(allUtility.filter((e) => e.utilityType === 3));
    //   utilityObj = {
    //     label: 'Merch',
    //     utility: allUtility.filter((e) => e.utilityType === 3),
    //   };
    //   sortedUtility.push(utilityObj);
    //   utilityObj = {};
    // }

    if (allUtility.filter((e) => e.utilityType === 2).length > 0) {
      utilityObj = {
        label: 'Latest Collections',
        utility: allUtility.filter((e) => e.utilityType === 2),
      };

      // check if the the gatedContents have a dateCreated
      if (utilityObj.utility[0].gatedContents[0].dateCreated) {
        // sort the utility by gatedContents dateCreated
        utilityObj.utility.sort((a, b) => new Date(b.gatedContents[0].dateCreated) - new Date(a.gatedContents[0].dateCreated));
      }

      sortedUtility.push(utilityObj);
      utilityObj = {};
    }

    if (allUtility.filter((e) => e.utilityType === 6 && moment(e.contests[0].endDate).unix() * 1000 > currentDate).length > 0) {
      utilityObj = {
        label: 'Contests',
        utility: allUtility.filter((e) => e.utilityType === 6 && moment(e.contests[0].endDate).unix() * 1000 > currentDate),
      };
      sortedUtility.push(utilityObj);
      utilityObj = {};
    }

    if (allUtility.filter((e) => e.utilityType === 4 || e.utilityType === 1 || e.utilityType === 3).length > 0) {
      utilityObj = {
        label: 'Offers',
        utility: allUtility.filter((e) => e.utilityType === 4 || e.utilityType === 1 || e.utilityType === 3),
      };
      if (utilityObj.utility[0].dateCreated) {
        // sort the utility by gatedContents dateCreated
        utilityObj.utility.sort((a, b) => new Date(b.dateCreated) - new Date(a.dateCreated));
      }
      sortedUtility.push(utilityObj);
      utilityObj = {};
    }

    if (allUtility.filter((e) => e.utilityType === 5).length > 0) {
      utilityObj = {
        label: 'Events',
        utility: allUtility.filter((e) => e.utilityType === 5),
      };
      sortedUtility.push(utilityObj);
      utilityObj = {};
    }

    return sortedUtility;
  }

  getFilteredUtility = (creatorId, filterType, token) => {
    const that = this;
    let filterName = 'gated-content';
    if (filterType === 1) {
      filterName = 'voucher';
    }
    if (filterType === 2) {
      filterName = 'gated-content';
    }
    if (filterType === 3) {
      filterName = 'gated-commerce';
    }
    if (filterType === 4) {
      filterName = 'generic';
    }
    return Ajax.get(that.apiUrl + 'utility/creator/' + creatorId, token)
      .then((data) => data)
      .catch((error) => {
        throw error;
      });
  }

  getAllUtility = (creatorId, token) => {
    const that = this;
    return Ajax.get(that.apiUrl + 'utility/creator/' + creatorId, token)
      .then((data) => {
        if (!data.utility || data.utility.length !== 0) {
          // TODO: update this so it includes loyalty utility
          that.allUtility = data.utility.collectionUtility;
          const { loyaltyUtility } = data.utility;
          if (loyaltyUtility && loyaltyUtility.length > 0) {
            loyaltyUtility.forEach((item) => {
              if (item.utilityType === 6) {
                that.allUtility.push(item);
              }
            });
          }
          // that.allUtility = data.utility.collectionUtility.concat(data.utility.nftUtility);
          return data;
        }
        return null;
      })
      .catch((error) => {
        throw error;
      });
  }

  getPreviewUtility = (assetId) => {
    const that = this;
    return Ajax.get(that.apiUrl + 'utility/summary/' + assetId)
      .then((data) => {
        if (data.utility && data.utility.length > 0) {
          return data.utility;
        }
        return null;
      })
      .catch((error) => {
        throw error;
      });
  }

  getContestRules = (contestId, token) => Ajax.get(this.apiUrl + 'Contest/byId?contestId=' + contestId, token)
    .then((data) => data)
    .catch((error) => {
      throw error;
    })

  claimVoucher = (utilityId, token) => Ajax.get(this.apiUrl + 'utility/' + utilityId + '/voucher/claim', token)
    .then((data) => data)
    .catch((error) => {
      throw error;
    })
}

export default new Utility();
